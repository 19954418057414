import { GetServerSidePropsResult, GetServerSidePropsContext, Viewport, NextApiRequest, NextApiResponse } from 'next'
import { NextSeo } from 'next-seo'
import seoConfig from '@/src/next-seo.config'
import { createServerSideHelpers } from '@trpc/react-query/server'
import { SlapWithTracksAndSkin } from '@/src/types'
import { createTRPCContext } from '@/src/server/context'
import { appRouter } from '@/src/server/routers/_app'
import Looper from '@/src/components/Looper'
import { baseUrl } from '@/src/util/constants'
import superjson from 'superjson'

export const viewport: Viewport = {
  themeColor: 'black'
}

interface HomePageProps {
  slap: SlapWithTracksAndSkin | null
}

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<HomePageProps>> {
  const { req, res } = context
  const slug = context?.params?.slug?.[0]
  let slap: SlapWithTracksAndSkin | null = null
  try {
    if (!slug) return { props: { slap: null } }
    const helpers = createServerSideHelpers({
      transformer: superjson,
      router: appRouter,
      ctx: await createTRPCContext({ req: req as NextApiRequest, res: res as NextApiResponse }) // Properly passing Next.js context to tRPC context
    })
    // @ts-ignore returns JsonValue[] instead of SongData[], but actual data is identical
    slap = await helpers.slaps.get.fetch({ slugOrId: slug })
  } catch (error) {
    console.error('error: ', error)
  }

  return { props: { slap } }
}

export default function HomePage({ slap }: HomePageProps) {
  if (slap) {
    seoConfig.title = slap.title
    seoConfig.openGraph.title = slap.title
    if (slap.slug) seoConfig.openGraph.url = `${baseUrl}/${slap.slug}`
    if (slap.skin.video) {
      seoConfig.openGraph.type = 'video.other'
      // @ts-ignore
      seoConfig.openGraph.videos = [{ url: slap.skin.video }]
    }
    if (slap.skin.image) {
      // @ts-ignore
      seoConfig.openGraph.images = [{ url: slap.skin.image }]
    }
  }
  return (
    <>
      <NextSeo {...seoConfig} />
      <Looper slap={slap} />
    </>
  )
}
