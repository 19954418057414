import { v4 } from 'uuid'
import getExtensionFromType from './getExtensionFromType'

export const base64ToFile = (base64: string) => {
  const [scheme, rawData] = base64.split(',')
  const mimeType = scheme.split(':')[1].split(';')[0]

  // note, atob only accepts the base64 data without the "data..." prefix
  const byteString = atob(rawData)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }

  const filename = `${v4()}${getExtensionFromType(mimeType)}`

  const blob = new Blob([arrayBuffer], { type: mimeType })
  const file = new File([blob], filename, { type: mimeType })

  return file
}
