import { create } from 'zustand'

interface KeyState {
  key: string
  currentKey: string | null
  setKey: (newKey: string) => void
  setCurrentKey: (newCurrentKey: string) => void
}

export const useKeyStore = create<KeyState>((set) => ({
  key: 'Cmaj',
  currentKey: null,
  setKey: (newKey) => set({ key: newKey }),
  setCurrentKey: (newCurrentKey) => set({ currentKey: newCurrentKey })
}))
