import { cn } from '@/src/lib/utils'

const PromptTemplateButtons = ({ onClick }: { onClick: (pt: PromptTemplate) => void }) => (
  <div className="flex flex-wrap gap-4 bg-transparent">
    {promptTemplates.map((pt) => {
      const { label } = pt
      return (
        <button
          title={`Use "${label}" preset`}
          key={`template-${label}`}
          onClick={() => {
            onClick(pt)
          }}
          className={cn('rounded bg-white px-2 text-black')}
        >
          {label}
        </button>
      )
    })}
  </div>
)

export default PromptTemplateButtons

export type PromptTemplate = {
  prompt: string
  label: string
  key: string
  bpm: number
}

// maybe allow bpm ranges or key: "minor or major"
const promptTemplates: PromptTemplate[] = [
  {
    prompt: 'soulful house music, minor 7th chords, rhodes piano, deep bassline, crispy analog percussion',
    label: 'House',
    key: 'F#min',
    bpm: 120
  },
  {
    prompt: 'ominous pad, plucky piano, minor triad, minor arpeggio, heavy reverb',
    label: 'Dark/Evil',
    key: 'Cmin',
    bpm: 70
  },
  {
    prompt: 'wah guitar, heavy delay, flanger, Rhodes, synth lead',
    label: 'Psychedelic',
    key: 'F#min',
    bpm: 90
  },
  {
    prompt: 'rhythmic wah guitar, electric piano, staccato bassline, horn stabs',
    label: 'Funky',
    key: 'F#maj',
    bpm: 92
  },
  {
    prompt: '7th chord Piano, 7th chord synth pads, guitar with heavy delay, staccato 7th chord strings',
    label: 'Soulful/R&B',
    key: 'Bbmin',
    bpm: 85
  },
  {
    prompt: 'minor 9th chord stab, staccato bassline, chopped vocal sample, quantized muted plucky synth',
    label: 'House II',
    key: 'Amin',
    bpm: 120
  }
]

const styleTransferTemplates = [
  {
    prompt: 'solo piano only',
    label: 'piano'
  },
  {
    prompt: 'rhodes piano only',
    label: 'rhodes'
  },
  {
    prompt: 'staccato bassline only',
    label: 'staccato bassline'
  },
  {
    prompt: 'horns only',
    label: 'horn'
  },
  {
    prompt: 'acoustic guitar only',
    label: 'acoustic guitar'
  },
  {
    prompt: 'congas only',
    label: 'congas'
  }
]

export type StyleTransferTemplate = Omit<PromptTemplate, 'key' | 'bpm'>

export const StyleTransferButtons = ({ onClick }: { onClick: (pt: StyleTransferTemplate) => void }) => (
  <div className="flex flex-wrap gap-4 bg-transparent">
    {styleTransferTemplates.map((pt) => {
      const { label } = pt
      return (
        <button
          key={`template-${label}`}
          title={`Use "${label}" preset`}
          onClick={() => onClick(pt)}
          className={cn('rounded bg-white px-2 text-black')}
        >
          {label}
        </button>
      )
    })}
  </div>
)
