'use client'

import { Track } from '@/src/context/audio-provider'
import { useSkin } from '@/src/context/skin-provider'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5'

import { cn } from '@/src/lib/utils'

import type { IconType } from 'react-icons/lib'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ProgressBar } from './ProgressBar'
import { useAnimationMachine } from '@/src/context/animation-machine-provider'
import chroma from 'chroma-js'
import { useBpmStore } from '../lib/bpmStore'
import { usePlayerStore } from '../lib/playerStore'

type PillProps = {
  track: Track
  trackIdx: number
  isSelected: boolean
  deleteTrack: (track: Track | null) => void
  toggleMuteTrack: (track: Track) => void
  onTrackActionComplete: (updatedTrack: Track) => void
  onSelect: (t: Track) => void
}

export type ActionItem = {
  id: string
  name: string
  description: string
  scopes: string[]
  Icon: IconType
  execute?: (t: Track | null) => void
  Component?: FC<{
    track: Track | null
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setIsOpen: Dispatch<SetStateAction<boolean>>
  }>
}

const Pill = ({ track, trackIdx, isSelected, toggleMuteTrack, onSelect }: PillProps) => {
  const { skin } = useSkin()
  const { currentBpm } = useBpmStore()
  const isPlaying = usePlayerStore((state) => state.isPlaying)
  const { beatDuration, firstDownbeatTime, animationIntensities, setAnimationIntensities, iconRefs, pillDotsRefs } =
    useAnimationMachine()
  const animationIntensity = animationIntensities[trackIdx] || 0

  const [muteAnimations, setMuteAnimations] = useState<{ timeoutId: NodeJS.Timeout; animationClass: string }[]>([])

  const mixpanel = useMixpanelContext()

  // ----- EFFECTS ----

  useEffect(
    () => () => {
      muteAnimations.forEach((animation) => clearTimeout(animation.timeoutId))
    },
    []
  )

  // ----- HANDLERS ----

  const handleMute = () => {
    mixpanel.track({
      eventName: 'ToggleMuteTrack',
      data: {
        muted: track.isMuted,
        duration: track.data[0].duration,
        bpm: track.data[0].bpm,
        key: track.data[0].key,
        skin
      }
    })

    // this variable is created before toggleMuteTrack() because toggleMuteTrack() might switch its value.
    const isMuted = track.isMuted
    toggleMuteTrack(track)

    // Disable the float anim
    if (!isPlaying || !isMuted) {
      setAnimationIntensities((prev) => {
        const newIntensities = [...prev]
        newIntensities[trackIdx] = 0
        return newIntensities
      })
      return
    }

    const timeoutId = setTimeout(() => {
      const getNewAnimation = (prev: typeof muteAnimations) => {
        const indexToRemove = prev.map((val) => val.timeoutId).indexOf(timeoutId)
        if (indexToRemove === -1) return prev
        const newArr = [...prev]
        newArr.splice(indexToRemove, 1)
        return newArr
      }
      setMuteAnimations((prev) => getNewAnimation(prev))
    }, 500)

    const currentPos = track.player.seek() // may need to * 1000 for some reason
    const absBeatTime = (currentPos + 10 * beatDuration - firstDownbeatTime * 1000) % beatDuration
    const triCurve = Math.abs(2 * absBeatTime - beatDuration)
    const closeness = triCurve / beatDuration

    // NOTE: due to CSS purge, the whole class must be together as one string
    const animationClass =
      closeness > 0.2
        ? closeness > 0.4
          ? closeness > 0.6
            ? closeness > 0.8
              ? 'animate-float-up-5'
              : 'animate-float-up-4'
            : 'animate-float-up-3'
          : 'animate-float-up-2'
        : 'animate-float-up-1'

    setAnimationIntensities((prev) =>
      prev.map((intensity, idx) =>
        trackIdx === idx
          ? Math.min(
              1,
              intensity +
                // a little bit of animation looks good and is rewarding in all cases even when the beat is off
                (1 / 3 + closeness * (2 / 3))
            )
          : intensity
      )
    )

    setMuteAnimations((prev) => [
      ...prev,
      {
        timeoutId: timeoutId,
        animationClass
      }
    ])
  }

  // ---- RENDER -----

  const defaultPillColor = 'rgba(255, 255, 255, 0)'
  const pillColor = chroma(defaultPillColor)
    .mix(skin.bgColor, Math.min(1, animationIntensity * 0.2))
    .hex()

  return (
    <div
      className={cn(
        'relative mt-4 h-12 min-h-12 w-full cursor-pointer select-none rounded-full backdrop-blur-xl',
        track.isMuted && 'opacity-25'
      )}
      style={{
        outline: isSelected ? `2px solid ${skin.bgColor}` : 'none',
        backgroundColor: pillColor
      }}
      onClick={handleMute}
    >
      <ProgressBar player={track.player} trackIdx={trackIdx} />
      <div className="absolute flex h-full w-full items-center justify-between gap-2 px-6">
        {muteAnimations.map((animation) => (
          <div
            key={`${animation.timeoutId}`}
            className={cn('pointer-events-none absolute z-[9999] flex-shrink-0', animation.animationClass)}
            style={{
              transitionDuration: currentBpm ? `${beatDuration}ms` : undefined
            }}
          >
            <IoVolumeHighOutline size={24} />
          </div>
        ))}
        <div className="flex flex-grow">
          <div
            className="origin-center"
            ref={(ref) => {
              if (!ref) return
              iconRefs.current.set(trackIdx, ref)
            }}
          >
            {track.isMuted ? <IoVolumeMuteOutline size={24} /> : <IoVolumeHighOutline size={24} />}
          </div>

          <div className="line-clamp-1 w-fit origin-center px-5 uppercase tracking-[9px] sm:text-lg">{track.title}</div>
        </div>

        <button
          ref={(ref) => {
            if (!ref) return
            pillDotsRefs.current.set(trackIdx, ref)
          }}
          onPointerDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onSelect(track)
          }}
        >
          <BsThreeDotsVertical size={24} />
        </button>
      </div>
    </div>
  )
}

export default Pill
