const getExtensionFromType = (type: string): string => {
  switch (type) {
    case 'audio/mpeg':
      return '.mp3'
    case 'audio/wav':
      return '.wav'
    case 'audio/ogg':
      return '.ogg'
    case 'audio/webm':
      return '.webm'
    default:
      return ''
  }
}

export default getExtensionFromType
