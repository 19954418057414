import { useEffect } from 'react'
import { Label } from './ui/label'
import { FiRotateCcw } from 'react-icons/fi'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { useTracks } from '../context/audio-provider'
import { useKeyStore } from '../lib/keyStore'

import { useSkin } from '../context/skin-provider'
import { musicalKeyOptions } from '../lib/utils'

const KeySelector = () => {
  const disableKeySelector = true
  const { skin } = useSkin()

  const key = useKeyStore((state) => state.key)
  const currentKey = useKeyStore((state) => state.currentKey)
  const setCurrentKey = useKeyStore((state) => state.setCurrentKey)

  const { updateKey } = useTracks()

  useEffect(() => {
    if (currentKey) updateKey(key, currentKey)
  }, [key, currentKey, updateKey])

  const resetKey = () => {
    setCurrentKey(key)
  }

  const inputValue = currentKey ? currentKey : key

  return (
    <div className="flex w-full flex-col items-center gap-2">
      <div className="flex w-[60%]">
        <Label className="text-left" htmlFor="key">
          Key
        </Label>
      </div>
      <div className="flex w-[60%] items-center justify-between">
        {disableKeySelector ? (
          <p className="text-sm">This feature is temporarily unavailable</p>
        ) : (
          <Select value={inputValue} onValueChange={(value) => setCurrentKey(value)}>
            <SelectTrigger
              title="Set Key"
              className="w-full"
              style={{
                backgroundColor: skin.bgColor,
                color: skin.textColor
              }}
            >
              <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent
              style={{
                backgroundColor: skin.bgColor,
                color: skin.textColor
              }}
            >
              {musicalKeyOptions.map((k) => (
                <SelectItem value={k} key={k}>
                  {k}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        {disableKeySelector ? null : (
          <button title="Reset Key" className="pl-2" onClick={resetKey}>
            <FiRotateCcw />
          </button>
        )}
      </div>
    </div>
  )
}

export default KeySelector
