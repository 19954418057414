import { cn } from '../lib/utils'
import { ImSpinner8 } from 'react-icons/im'

const Loader = ({
  left,
  text = 'Loading...',
  subText,
  relative,
  className,
  spinnerClassName
}: {
  left?: boolean
  text?: string | boolean
  subText?: string
  relative?: boolean
  className?: string
  spinnerClassName?: string
}) => (
  <div
    className={cn(
      'loader-root',
      'flex h-full w-fit items-center',
      relative ? 'relative' : 'fixed left-1/2 right-1/2 top-0 -translate-x-1/2 transform lg:h-screen',
      className
    )}
  >
    <div className={cn('flex flex-col', left ? '' : 'items-center')}>
      {/* styles found in loader.css */}
      {/* It's fine to not use tailwind to handle this animation because putting animations in a css file is less cumbersome than putting them in tailwind.config, especially if they are only used once. */}
      <Spinner className={cn(spinnerClassName)} />
      {!!text && <span className="mt-[13px] min-w-max text-xs">{text}</span>}
      {!!subText && <span className="mt-2 min-w-max text-[10px] text-white/60">{subText}</span>}
    </div>
  </div>
)

export default Loader

export const Spinner = ({ className }: { className?: string }) => (
  <ImSpinner8 className={cn('h-4 w-4 animate-spin', className)} />
)
