import { FiCheckCircle } from 'react-icons/fi'
import { toast } from 'sonner'
import copy from 'copy-to-clipboard'
import { IoCopy } from 'react-icons/io5'
import { PiLinkThin } from 'react-icons/pi'
import isUrlBase64 from '@/src/util/isUrlBase64'
import uploadBase64 from '@/src/util/uploadBase64'

export const copyAndToast = async (text: string, successText?: string) => {
  const isBase64 = isUrlBase64(text)

  if (!isBase64) {
    toastTheLink(text, successText)
    return
  }

  toast(
    <div className="flex w-full items-center justify-between">
      <span>{'Preparing link...'}</span>
      <PiLinkThin />
    </div>
  )

  const uploadUrl = await uploadBase64(text)

  // Do not throw, the app does not need to crash if this does not work.
  if (!uploadUrl) {
    console.error('Error: Could not get upload URL for link.')
    return
  }

  toastTheLink(uploadUrl, successText)
}

export const toastTheLink = (text: string, successText?: string) => {
  toast(
    <div className="flex w-full justify-between">
      <span>{successText ?? 'Click to Copy link'}</span>
      <button
        title="Copy Link"
        onClick={() => {
          copy(text)
          toast(
            <div className="flex w-full justify-between">
              <span>Link Copied!</span>
              <FiCheckCircle className="text-green-500" />
            </div>
          )
        }}
        className="hover:text-primary active:text-primary/80"
      >
        <IoCopy />
      </button>
    </div>
  )
}
