import { EndpointCompletedOutput, EndpointIncompleteOutput } from 'runpod-sdk'

import { Track } from './context/audio-provider'
import { Skin, Slap, Role } from '@prisma/client'

export { Role }
export const isEndpointCompletedOutput = (
  result: EndpointCompletedOutput | EndpointIncompleteOutput | undefined
): result is EndpointCompletedOutput => (result as EndpointCompletedOutput)?.output !== undefined

export type SlapWithTracksSerializable = Omit<Slap, 'updatedAt' | 'createdAt'> & {
  tracks: Omit<Track, 'player'>[]
}

export type SlapWithTracksAndSkinSerializable = SlapWithTracksSerializable & {
  skin: Skin
}

export type SlapWithTracks = Slap & {
  tracks: Omit<Track, 'player'>[]
}

export type SlapWithTracksAndSkin = SlapWithTracks & {
  skin: Skin
}

export enum Instrument {
  BASS = 'bass',
  DRUMS = 'drums',
  GUITAR = 'guitar',
  PIANO = 'piano',
  FLUTE = 'flute',
  VOCALS = 'vocals',
  RECORDING = 'recording',
  OTHER = 'other',
  NO_VOCALS = 'no_vocals'
}

type StemsOutput = {
  stems: {
    [key in Instrument]?: string
  }
}

export const isStemsResult = (result: any): result is StemsResult =>
  (result as StemsResult)?.output !== undefined && (result as StemsResult)?.output?.stems !== undefined

export interface StemsResult {
  delayTime?: number
  executionTime?: number
  id: string
  output: StemsOutput
  status: string // it's really RunpodStatus but that messes up types
  started: boolean
  completed: boolean
  succeeded: boolean
  error?: string
}

export enum Status {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  Empty = 'empty',
  Idle = 'idle'
}
