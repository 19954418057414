import { upload } from '@vercel/blob/client'
import { base64ToFile } from './base64ToFile'

const uploadBase64 = async (base64String: string) => {
  const file = base64ToFile(base64String)
  // error message logged in base64ToFile function
  if (!file) return

  return (
    await upload(file.name, file, {
      access: 'public',
      handleUploadUrl: '/api/blob/upload'
    })
  ).url
}

export default uploadBase64
