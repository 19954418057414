const jobFinish = async <T>({
  func,
  condition,
  retry = 0
}: {
  func: () => Promise<T> | T
  condition?: (resp: T) => boolean
  retry?: number
}): Promise<T> => {
  const interval = 2000
  const maxRetries = 150
  const resp = await func()
  if (condition ? condition(resp) : resp) {
    return Promise.resolve(resp)
  } else if (retry >= maxRetries) {
    return Promise.reject(new Error('Retries exhausted'))
  }
  await new Promise((resolve) => setTimeout(resolve, interval))
  return jobFinish({ func, condition, retry: retry + 1 })
}

export default jobFinish
