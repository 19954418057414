export const MAX_RUNPOD_BODY_SIZE = 10 // in MB

// the output from the stem splitting is unique in the sense that the filesize of the stems output depends not on the input's filesize but instead the input's duration
// So, for every one second increase in duration, the filesize for one stem will increase by this many MB
export const STEMS_DURATION_FILESIZE_RATIO = 0.04001144366 // in MB

// currently, each output from the runpod gen service is 919KB large for every 4 seconds of audio
export const FILESIZE_PER_SECOND = 0.919 / 4

export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL ?? 'http://localhost:3000'
