import { useEffect } from 'react'
import { FiRotateCcw } from 'react-icons/fi'

import { Slider } from '@/src/components/ui/slider'
import { Label } from '@/src/components/ui/label'
import { useTracks } from '../context/audio-provider'
import { useBpmStore } from '../lib/bpmStore'
import { useSkin } from '../context/skin-provider'

const BpmSlider = () => {
  const bpm = useBpmStore((state) => state.bpm)
  const currentBpm = useBpmStore((state) => state.currentBpm)
  const setCurrentBpm = useBpmStore((state) => state.setCurrentBpm)
  const { updateBpm } = useTracks()
  const { skin } = useSkin()

  useEffect(() => {
    if (currentBpm) updateBpm(currentBpm)
  }, [currentBpm, updateBpm])

  const resetBpm = () => {
    setCurrentBpm(bpm)
    updateBpm(bpm, true)
  }

  const sliderValue = currentBpm ? currentBpm : bpm
  return (
    <div className="flex w-full flex-col items-center gap-2 pl-5">
      <div className="flex w-full md:w-[60%]">
        <Label htmlFor="bpm" className="text-left">
          BPM <span>{sliderValue}</span>
        </Label>
      </div>
      <div className="flex w-full items-center justify-between md:w-[60%] md:flex-row">
        <Slider
          title="Set BPM"
          value={[sliderValue]}
          max={300}
          min={1}
          step={1}
          onValueChange={(value: number[]) => setCurrentBpm(value[0])}
          color={skin.bgColor}
        />
        <button title="Reset BPM" className="pl-2" onClick={resetBpm}>
          <FiRotateCcw />
        </button>
      </div>
    </div>
  )
}

export default BpmSlider
