'use client'

import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  MutableRefObject
} from 'react'
import * as Tone from 'tone'
import { constant, times } from 'lodash'
import { useBpmStore } from '../lib/bpmStore'
import { useSkin } from './skin-provider'
import { useTracks } from './audio-provider'

// ————— types —————

type AnimationMachineContextState = {
  animationIntensities: number[]
  setAnimationIntensities: Dispatch<SetStateAction<number[]>>
  resetAnimations: () => void
  progressBarRefs: MutableRefObject<Map<number, HTMLDivElement>>
  iconRefs: MutableRefObject<Map<number, HTMLDivElement>>
  addButtonRef: MutableRefObject<HTMLDivElement | null>
  pillDotsRefs: MutableRefObject<Map<number, HTMLButtonElement>>
  endTime: number
  numberOfBeats: number
  firstDownbeatTime: number
  beatDuration: number
}

// ————— context —————
const defaultState: AnimationMachineContextState = {
  animationIntensities: [],
  setAnimationIntensities: () => {},
  resetAnimations: () => {},
  progressBarRefs: { current: new Map() },
  iconRefs: { current: new Map() },
  addButtonRef: { current: null },
  pillDotsRefs: { current: new Map() },
  endTime: 0,
  numberOfBeats: 0,
  firstDownbeatTime: 0,
  beatDuration: 0
}

const AnimationMachineContext = createContext<AnimationMachineContextState>(defaultState)

// ————— provider —————

export const AnimationMachineProvider: FC<{
  children: ReactNode
  isPlaying: boolean
  downbeatTimes: number[] // in seconds
  tracksLength: number
}> = ({
  children,
  isPlaying,
  // downbeatTimes, // in seconds
  tracksLength
}) => {
  const { bpm, currentBpm } = useBpmStore()

  const { tracks } = useTracks()
  const track = tracks[0]

  const beatDuration = 60000 / (currentBpm || bpm)
  // Do not delete this commented out line.
  // Currently, downbeat times are not accurate enough to be usable. However, this might change in the future.
  // const firstDownbeatTime = ((downbeatTimes[0] || 0) * 1000 * (bpm / (currentBpm || bpm))) % beatDuration
  const firstDownbeatTime = 0

  // note, loopEnd is already adjusted based on currentBpm, but duration is not
  // const endTime = (Tone.Transport.loopEnd || 4) as number
  const endTime = (track?.player.duration() || 4) as number
  // If downbeat times do not exist, then it is a premade slap.
  // All premade slaps have their first beat at 0ms and follow the BPM perfectly.
  const numberOfBeats = Math.round((endTime * 1000) / beatDuration)

  // The animation intensities for each track. The length of this array should always be equal to the length of tracks.
  // Note, it's better to have this NOT a part of the Track type because this will change extremely often, and any effect with "tracks" as a dependency will fire whenever it changes.
  const [animationIntensities, setAnimationIntensities] = useState<number[]>(times(tracksLength, constant(0)))

  const progressBarRefs = useRef<Map<number, HTMLDivElement>>(new Map())
  const iconRefs = useRef<Map<number, HTMLDivElement>>(new Map())
  const addButtonRef = useRef<HTMLDivElement | null>(null)
  const pillDotsRefs = useRef<Map<number, HTMLButtonElement>>(new Map())

  const { skin } = useSkin()

  const animDataRef = useRef<{
    isPlaying: boolean
    tracksLength: number
    numberOfBeats: number
    firstDownbeatTime: number
    beatDuration: number
    animationIntensities: number[]
    bgColor: string
  }>({
    isPlaying,
    tracksLength,
    numberOfBeats,
    firstDownbeatTime,
    beatDuration,
    animationIntensities,
    bgColor: skin.bgColor
  })

  const reset = () => {
    setAnimationIntensities(times(tracksLength, constant(0)))
    progressBarRefs.current.forEach((el) => {
      el.style.clipPath = `inset(-64px 100% -64px 0)`
    })
  }

  useEffect(() => {
    // Variables that do NOT automatically adjusted when bpm changes: bpm, downbeatTimes
    // Variables that automatically adjust when bpm changes: currentBpm, beatDuration, firstDownbeatTime, Tone.Transport.loopEnd, beatDuration, Tone.Transport.seconds (but only due to logic in useProgressTracking.ts)

    animDataRef.current = {
      isPlaying,
      tracksLength,
      numberOfBeats,
      firstDownbeatTime,
      beatDuration,
      animationIntensities,
      bgColor: skin.bgColor
    }
  }, [
    isPlaying,
    tracksLength,
    numberOfBeats,
    endTime,
    firstDownbeatTime,
    beatDuration,
    animationIntensities,
    skin.bgColor,
    currentBpm,
    bpm
  ])

  useEffect(() => {
    // WARNING: do not use any react state in this loop. Any react state must be put into animDataRef to be usable here.
    const loop = () => {
      if (
        !animDataRef.current.isPlaying ||
        !progressBarRefs.current?.size ||
        !animDataRef.current.tracksLength ||
        typeof Tone.Transport.loopEnd !== 'number'
      ) {
        requestAnimationFrame(loop)
        return
      }

      const animatePulsars = () => {
        if (!track) return // sometimes the track state hasn't been updated by the time the user presses play after an intial generation
        const pulseDuration = 0.1875 // 3/16 of a beat

        const progressAsRate = track.player.seek() / track.player.duration()

        const progressAsBeat =
          (progressAsRate * animDataRef.current.numberOfBeats + animDataRef.current.numberOfBeats) %
          animDataRef.current.numberOfBeats
        const beatProgress = progressAsBeat % 1

        const risingOrFalling = beatProgress > 1 - pulseDuration ? 'rising' : 'falling'
        let pulseIntensity: number
        if (risingOrFalling === 'rising') {
          const linearIntensity = (beatProgress - 1 + pulseDuration) / pulseDuration
          pulseIntensity = linearIntensity
        } else {
          const linearIntensity = 1 - beatProgress / (1 - pulseDuration)
          pulseIntensity = linearIntensity
        }

        const pillScaleMultiplier = 0.06
        const makePillStyles = (el: HTMLElement, trackIdx: number) => {
          const addedScale =
            pillScaleMultiplier + animDataRef.current.animationIntensities[trackIdx] * pillScaleMultiplier
          const newScale = 1 + pulseIntensity * addedScale
          el.style.transform = `scaleY(${newScale})`
        }

        progressBarRefs.current.forEach((el, trackIdx) => makePillStyles(el, trackIdx))

        iconRefs.current.forEach((el, trackIdx) => {
          const animationIntensity = animDataRef.current.animationIntensities[trackIdx]

          const addedScale = 0.09 + animationIntensity * 0.09
          const newScaleX = 1 + pulseIntensity * addedScale
          // needed to adjust for how modifying the pill scale also scaled its children
          const newScaleY =
            1 +
            pulseIntensity *
              addedScale *
              (1 - (pillScaleMultiplier + pillScaleMultiplier * animationIntensity) * pulseIntensity)
          el.style.transform = `scale(${newScaleX}, ${newScaleY})`
        })

        if (addButtonRef.current) {
          const addedScale = 0.075
          const newScale = 1 + pulseIntensity * addedScale
          addButtonRef.current.style.transform = `scale(${newScale})`

          const firstChild = addButtonRef.current.firstChild as HTMLElement | null
          if (firstChild) {
            const addedScale = 0.05
            const newScale = 1 + pulseIntensity * addedScale
            firstChild.style.transform = `scale(${newScale})`
          }
        }

        pillDotsRefs.current.forEach((el, trackIdx) => {
          const animationIntensity = animDataRef.current.animationIntensities[trackIdx]

          const addedScale = 0.1 + animationIntensity * 0.1
          const newScaleX = 1 + pulseIntensity * addedScale
          // needed to adjust for how modifying the pill scale also scaled its children
          const newScaleY =
            1 +
            pulseIntensity *
              addedScale *
              (1 - (pillScaleMultiplier + pillScaleMultiplier * animationIntensity) * pulseIntensity)
          el.style.transform = `scale(${newScaleX}, ${newScaleY})`
        })
      }

      animatePulsars()

      requestAnimationFrame(loop)
    }

    const animFrame = requestAnimationFrame(loop)

    return () => cancelAnimationFrame(animFrame)
    // This should have no dependencies. All state that would otherwise go into a dependency should instead be added to animDataRef instead.
    // This is because requestAnimationFrame cannot use React state, so if we need any React State in the requestAnimationFrame, it needs to be put into a ref first.
    // The ref we are using for this is `animDataRef`
  }, [tracks])

  useEffect(() => {
    setAnimationIntensities(times(tracksLength, constant(0)))
  }, [tracksLength])

  useEffect(() => {
    if (!(currentBpm || bpm)) return
    const interval = setInterval(
      () => {
        setAnimationIntensities((prev) => prev.map((intensity) => Math.max(0, intensity - 3 / (currentBpm || bpm))))
      },
      30000 / (currentBpm || bpm)
    )
    return () => clearInterval(interval)
  }, [currentBpm, bpm, tracksLength])

  // ————— render —————

  return (
    <AnimationMachineContext.Provider
      value={{
        animationIntensities,
        setAnimationIntensities,
        resetAnimations: reset,
        progressBarRefs,
        addButtonRef,
        iconRefs,
        pillDotsRefs,
        endTime,
        numberOfBeats,
        firstDownbeatTime,
        beatDuration
      }}
    >
      {children}
    </AnimationMachineContext.Provider>
  )
}

// ————— hooks —————
export const useAnimationMachine = () => useContext(AnimationMachineContext)
