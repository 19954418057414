import React, { useEffect, useRef, useState } from 'react'
import { usePlayerStore } from '../lib/playerStore'
import { useAnimationMachine } from '../context/animation-machine-provider'

interface TrackWithPlayer {
  player: {
    seek: () => number // Method to get current playback position
    duration: () => number // Method to get total duration
  }
}

export const ProgressBar = ({ trackIdx, player }: { trackIdx: number; player: TrackWithPlayer['player'] }) => {
  const requestRef = useRef<number | null>(null)
  const isPlaying = usePlayerStore((state) => state.isPlaying)
  const [progress, setProgress] = useState(0) // State to trigger re-renders and animate

  const { progressBarRefs } = useAnimationMachine()

  const animateProgress = () => {
    const currentProgress = player.seek() / player.duration()
    setProgress(currentProgress) // Update progress state
    requestRef.current = requestAnimationFrame(animateProgress) // Continue the animation loop
  }

  useEffect(() => {
    if (isPlaying) {
      requestRef.current = requestAnimationFrame(animateProgress)
    } else if (requestRef.current !== null) {
      cancelAnimationFrame(requestRef.current)
      requestRef.current = null
    }

    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current)
        requestRef.current = null
      }
    }
  }, [isPlaying])

  return (
    <div
      ref={(ref) => {
        if (!ref) return
        progressBarRefs.current.set(trackIdx, ref)
      }}
      className="absolute left-0 top-0 flex h-full items-center rounded-l-full bg-black/10"
      style={{ width: `${progress * 100}%` }}
    />
  )
}
