'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { HBox } from 'intuitive-flexbox'
import { FiZap } from 'react-icons/fi'
import { LuPlus } from 'react-icons/lu'
import { PiLinkThin } from 'react-icons/pi'
import { BiSolidError } from 'react-icons/bi'
import { useSwipeable } from 'react-swipeable'
import * as Popover from '@radix-ui/react-popover'

import { clientTrpc } from '@/src/lib/trpc'

import { toast } from 'sonner'
import { saveAs } from 'file-saver'
import { EndpointIncompleteOutput } from 'runpod-sdk'

import { cn } from '@/src/lib/utils'
import { runpod } from '@/src/lib/runpod'
import jobFinish from '@/src/util/jobFinish'
import * as recording from '@/src/util/recorder'
import { SPLIT_STEMS_RUNPOD_ID, VANILLA_MUSICGEN_ID } from '@/src/lib/endpoints'

import { useSkin } from '@/src/context/skin-provider'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { SongData, Track, useTracks } from '@/src/context/audio-provider'

import Loader from '@/src/components/Loader'
import { Progress } from '@/src/components/ui/progress'

import { SlapWithTracksAndSkinSerializable, StemsResult, isEndpointCompletedOutput, isStemsResult } from '@/src/types'
import PromptTemplateButtons, { PromptTemplate } from './PromptTemplateButtons'
import { copyAndToast } from '@/src/components/Toast'
import Transport from './Transport'
import Pill from './Pill'
import { useBpmStore } from '@/src/lib/bpmStore'
import { useKeyStore } from '@/src/lib/keyStore'
import { usePlayerStore } from '@/src/lib/playerStore'

import { MAX_RUNPOD_BODY_SIZE } from '@/src/util/constants'
import uploadBase64 from '@/src/util/uploadBase64'
import isUrlBase64 from '@/src/util/isUrlBase64'
import { ActionMenu } from '@/src/components/actions'
import { AnimationMachineProvider, useAnimationMachine } from '@/src/context/animation-machine-provider'

const Looper = ({ slap }: { slap: SlapWithTracksAndSkinSerializable | null }) => {
  const recorder = useRef<recording.Recorder | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const isRequestingRef = useRef(false)
  const slapId = useRef<string | null>(null)

  const refPassthrough = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el)
    // set myRef el so you can access it yourself
    scrollRef.current = el
  }

  const { skin, setSkin } = useSkin()
  const mixpanel = useMixpanelContext()

  const {
    tracks,
    setTracks,
    playAll,
    pauseAll,
    toggleMuteTrack,
    deleteTrack,
    addNewTrack,
    lockedDuration,
    getPlayer,
    setLockedDuration,
    setAllTracksLoaded
  } = useTracks()

  const bpm = useBpmStore((state) => state.bpm)
  const currentBpm = useBpmStore((state) => state.currentBpm)
  const setBpm = useBpmStore((state) => state.setBpm)
  const setCurrentBpm = useBpmStore((state) => state.setCurrentBpm)

  const key = useKeyStore((state) => state.key)
  const currentKey = useKeyStore((state) => state.currentKey)
  const setKey = useKeyStore((state) => state.setKey)
  const setCurrentKey = useKeyStore((state) => state.setCurrentKey)

  const isPlaying = usePlayerStore((state) => state.isPlaying)

  const handlers = useSwipeable({
    trackMouse: true
  })

  const [currentPrompt, setCurrentPrompt] = useState<string>('')
  const [showPopover, setShowPopover] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSplitting, setIsSplitting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [mounted, setMounted] = useState(false)
  const [copying, setCopying] = useState(false) // eslint-disable-line unused-imports/no-unused-vars
  const [slapTitle, setSlapTitle] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [selectedTrack, setSelectedTrack] = useState<Track | null>(null)
  const [downbeatTimes, setDownbeatTimes] = useState<number[]>([])

  const update = async () => {
    if (isRequestingRef.current) return
    if (!bpm || !key || !lockedDuration || !slapTitle || tracks.length === 0) return

    mixpanel.track({
      eventName: 'ShareButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })

    isRequestingRef.current = true
    setCopying(true)
    toast(
      <div className="flex w-full items-center justify-between">
        <span>{'Preparing link...'}</span>
        <PiLinkThin />
      </div>
    )

    try {
      const uploadedTrackUrls = await Promise.all(
        tracks.map(async (track) => {
          if (isUrlBase64(track.src)) {
            const signedUrl = await uploadBase64(track.src)
            if (!signedUrl) throw new Error('Error: Could not create signed URLs when getting AppState.')
            return signedUrl
          }
          return track.src
        })
      )

      const appTracks = tracks.map((track, idx) => {
        const { player, pitchShiftNode, ...rest } = track // eslint-disable-line unused-imports/no-unused-vars
        rest.src = uploadedTrackUrls[idx]
        rest.data = rest.data.map((datum) => ({
          ...datum,
          // TODO: currently, there is always only one member in "data", and its "audio" is always equal to the track src. Should we refactor to simplify this?
          audio: uploadedTrackUrls[idx]
        }))
        return rest
      })
      const slap = {
        bpm,
        currentBpm,
        key,
        currentKey,
        duration: lockedDuration,
        title: slapTitle,
        skinId: skin.id,
        tracks: appTracks,
        parentId: slapId.current
      }

      const { id, slug } = await clientTrpc.slaps.create.mutate({
        slap: {
          ...slap,
          // TODO: There has to be cleaner way to handle this
          currentBpm: slap.currentBpm ?? undefined,
          currentKey: slap.currentKey ?? undefined,
          parentId: slap.parentId ?? undefined
        }
      })
      const shareURL = new URL(`${window.location.origin}/${slug ?? id}`)
      slapId.current = id
      copyAndToast(shareURL.href)
    } catch (error: any) {
      // todo: handle error
      console.error(error)
      toast(
        <div className="flex w-full justify-between">
          <span>Something went wrong 😕</span>
          <BiSolidError className="text-red-500" />
        </div>
      )
    } finally {
      setCopying(false)
      isRequestingRef.current = false
    }
  }

  const initSlap = useCallback(async () => {
    if (!slap) {
      setMounted(true)
      return
    }
    slapId.current = slap.id
    setShowPopover(false)
    if (bpm !== slap.bpm) setBpm(slap.bpm)
    if (slap.currentBpm && slap.currentBpm !== slap.bpm) setCurrentBpm(slap.currentBpm)
    if (key !== slap.key) setKey(slap.key)
    const debug = slap.tracks[0]?.data[0]?.debug
    if (debug && 'downbeat_times' in debug) setDownbeatTimes(debug.downbeat_times)
    setLockedDuration(slap.duration)
    setSlapTitle(slap.title)
    setSkin(slap.skin)

    const initTracks = slap.tracks.map((track: Omit<Track, 'player'>) => ({
      ...track,
      player: getPlayer(track.src)
    }))
    setTracks(initTracks)
    if (slap.currentKey && slap.currentKey !== slap.key) setCurrentKey(slap.currentKey)
    setMounted(true)
  }, [
    bpm,
    currentBpm,
    setBpm,
    setCurrentBpm,
    key,
    setKey,
    setLockedDuration,
    setSkin,
    setTracks,
    setCurrentKey,
    setAllTracksLoaded,
    tracks
  ])

  useEffect(() => {
    if (mounted) {
      return
    }

    if (!slap) {
      setMounted(true)
      return
    }
    initSlap()
  }, [initSlap, mounted])

  useEffect(() => {
    isLoading &&
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight,
        behavior: 'smooth'
      })
  }, [isLoading])

  useEffect(() => {
    const interval = setInterval(() => {
      const nextProgress = progress >= 100 ? 100 : progress + 0.41
      setProgress(nextProgress)
      if (!isLoading) {
        setProgress(0)
        clearInterval(interval)
      }
    }, 50)
    return () => clearInterval(interval)
  }, [isLoading, progress])

  const callGenAPI = async () => {
    try {
      setIsLoading(true)
      if (!slapTitle) {
        setSlapTitle(
          await clientTrpc.name.generate.mutate({
            prompt: currentPrompt
          })
        )
      }
      const promptKey = currentKey ? currentKey : key
      const promptBpm = currentBpm ? currentBpm : bpm
      const prompt = `${currentPrompt} bpm:${promptBpm} key:${promptKey}`
      let genJobID: string | undefined
      try {
        genJobID = await clientTrpc.musicGen.genLoop.mutate({
          prompt,
          duration: lockedDuration
        })
      } catch (err) {
        console.error(err)
        throw new Error('Runpod job did not start successfully')
      }

      if (!genJobID) throw new Error('Runpod job did not start successfully')

      const genEndpoint = runpod.endpoint(VANILLA_MUSICGEN_ID)
      const resp = await jobFinish({
        func: async () => genEndpoint?.status(genJobID),
        condition: (resp) => {
          if (resp?.status === 'FAILED') console.error('Error generating loop: ', resp)
          return resp?.status === 'COMPLETED' || resp?.status === 'FAILED'
        },
        retry: -Infinity
      })

      if (!isEndpointCompletedOutput(resp)) {
        console.error(resp)
        setProgress(100)
        throw new Error('Runpod job did not complete successfully')
      }
      const songData: SongData[] = resp.output.outputs

      // although it's possible that multiple tracks could have different downbeat times, all tracks need to share the same downtime time regardless for the app to function. Therefore, we just use the first track's downtime time for all tracks.
      const debug = songData[0]?.debug
      if (debug && 'downbeat_times' in debug) setDownbeatTimes(debug.downbeat_times)

      setProgress(100)
      if (!lockedDuration) {
        setLockedDuration(songData[0].duration)
      }
      setIsSplitting(true)

      let inputUrl = songData[0].audio
      const isBase64 = songData[0].audio.startsWith('data:')
      if (isBase64) {
        // size of string is 2 bytes per character
        const sizeInMB = (songData[0].audio.length * 2) / 1024 / 1024
        if (sizeInMB > MAX_RUNPOD_BODY_SIZE) {
          const uploadedUrl = await uploadBase64(inputUrl)
          if (!uploadedUrl) throw new Error('Error: base64 audio string is corrupted.')
          inputUrl = uploadedUrl
        }
      }

      let splitJobID: string | undefined
      try {
        splitJobID = await clientTrpc.stems.splitStems.mutate({
          audio: inputUrl,
          duration: songData[0].duration
        })
      } catch (err) {
        console.error(err)
        throw new Error('Runpod Stem Split job did not start successfully')
      }

      if (!splitJobID) throw new Error('Runpod Stem Split job did not start successfully')

      const stemsEndpoint = runpod.endpoint(SPLIT_STEMS_RUNPOD_ID)

      const result = await jobFinish<StemsResult | EndpointIncompleteOutput | undefined>({
        func: async () => stemsEndpoint?.status(splitJobID),
        condition: (statusResponse) => {
          if (!statusResponse) console.error('Failed to split stems. No response from server.')
          if (statusResponse?.status === 'FAILED') console.error('Failed to split stems. Response: ', statusResponse)
          return statusResponse?.status === 'COMPLETED' || statusResponse?.status === 'FAILED'
        }
      })

      if (!isStemsResult(result)) {
        throw new Error('Failed to split stems')
      }

      const stemUrls = result.output?.stems

      setIsSplitting(false)

      await Promise.all(
        Object.entries(stemUrls).map(async ([name, url]) => {
          const stemSongData = [
            {
              ...songData[0],
              audio: url
            }
          ]
          const track = await addNewTrack(stemSongData, name)
          mixpanel.track({
            eventName: 'AddNewTrack',
            data: {
              currentPrompt,
              duration: lockedDuration,
              bpm,
              currentBpm,
              key,
              title: slapTitle,
              skinId: skin.id,
              track: {
                id: track?.id,
                title: track?.title,
                isSoloed: track?.isSoloed,
                isMuted: track?.isMuted
              },
              trackLength: tracks.length,
              parentId: slapId.current
            }
          })
        })
      )
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
      toast(
        <div className="flex w-full justify-between">
          <span>Something went wrong 😕</span>
          <BiSolidError className="text-red-500" />
        </div>
      )
    }
  }

  const startRecording = async () => {
    if (recorder.current) return // already recording
    // TODO: future feature: allow users to pipe in more audio using getUserMedia
    recorder.current = recording.createRecorder('wav')
    if (!isPlaying) playAll()
    recorder.current.start()
    setIsRecording(true)

    mixpanel.track({
      eventName: 'StartRecordingButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })
  }

  const stopRecording = async () => {
    if (!recorder.current) return // already stopped
    const inst = recorder.current
    recorder.current = null
    const wavBlob = await inst.stop()
    setIsRecording(false)
    saveAs(wavBlob, `${slapTitle ? slapTitle : 'untitled'}.wav`)

    mixpanel.track({
      eventName: 'StopRecordingButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })
  }

  const PlusButton = () => (
    <HBox center>
      <Popover.Root
        open={showPopover}
        onOpenChange={(open) => {
          setShowPopover(open)
          mixpanel.track({
            eventName: 'PopoverOpenClose',
            data: {
              popoverOpen: open,
              currentPrompt,
              duration: lockedDuration,
              bpm,
              currentBpm,
              key,
              title: slapTitle,
              skinId: skin.id,
              trackLength: tracks.length,
              parentId: slapId.current,
              currentKey
            }
          })
        }}
      >
        <Popover.Trigger className="content-center">
          <AddButton />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="!z-[9999] rounded bg-black text-white">
            <Popover.Arrow
              className="fill-primary"
              style={{
                fill: skin.bgColor
              }}
            />
            <div
              className="flex max-w-[340px] flex-col items-center gap-8 rounded bg-primary p-4"
              style={{
                backgroundColor: skin.bgColor,
                color: skin.textColor
              }}
            >
              <textarea
                ref={textareaRef}
                onChange={(e) => setCurrentPrompt(e.target.value)}
                value={currentPrompt}
                cols={30}
                rows={4}
                className="rounded bg-transparent p-2 outline-primary-foreground"
                style={{
                  outlineColor: skin.textColor
                }}
                placeholder="soulful house music minor 7th chords rhodes piano deep bassline crispy analog percussion"
              />
              <PromptTemplateButtons
                onClick={(pt: PromptTemplate) => {
                  setCurrentPrompt(pt.prompt)
                  // Don't update bpm if tracks have already been generated
                  if (!tracks.length) {
                    setBpm(pt.bpm)
                    setKey(pt.key)
                  }
                  !!textareaRef.current && textareaRef.current.focus()
                }}
              />
              <button
                className="flex w-full items-center justify-center gap-2 rounded-full bg-white px-2 py-1 font-medium text-primary disabled:cursor-not-allowed disabled:opacity-30"
                disabled={!currentPrompt || isLoading}
                onClick={() => {
                  callGenAPI()
                  setShowPopover(false)
                  mixpanel.track({
                    eventName: 'PromptButtonClick',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      currentBpm,
                      key,
                      currentKey,
                      title: slapTitle,
                      skinId: skin.id,
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                }}
              >
                <span>Generate</span>
                <FiZap />
              </button>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </HBox>
  )

  return !mounted ? (
    <Loader />
  ) : (
    <AnimationMachineProvider isPlaying={isPlaying} tracksLength={tracks.length} downbeatTimes={downbeatTimes}>
      <main
        className="flex h-screen w-full flex-col items-center gap-4 overflow-y-auto px-4 pt-8 sm:px-16"
        {...handlers}
        ref={refPassthrough}
      >
        <span className="text-md tracking-wides mt-6 uppercase">{slapTitle}</span>

        <div className="mb-24 w-full pb-32">
          {tracks.map((track, trackIdx) => (
            <div className="flex w-full flex-col" key={track.id}>
              <Pill
                track={track}
                trackIdx={trackIdx}
                isSelected={selectedTrack?.id === track.id}
                onSelect={(t: Track) => {
                  setSelectedTrack((prev) => (prev === t ? null : t))
                }}
                deleteTrack={() => {
                  deleteTrack(track)
                  mixpanel.track({
                    eventName: 'DeleteTrackButtonClick',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      key,
                      currentBpm,
                      currentKey,
                      title: slapTitle,
                      skinId: skin.id,

                      track: {
                        id: track.id,
                        title: track.title,
                        isSoloed: track.isSoloed,
                        isMuted: track.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                }}
                toggleMuteTrack={() => {
                  toggleMuteTrack(track)
                  mixpanel.track({
                    eventName: 'ToggleMuteTrackButtonClick',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      currentBpm,
                      key,
                      title: slapTitle,
                      skinId: skin.id,
                      currentKey,
                      track: {
                        id: track.id,
                        title: track.title,
                        isSoloed: track.isSoloed,
                        isMuted: track.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                }}
                onTrackActionComplete={(_t: Track) => {
                  mixpanel.track({
                    eventName: 'TrackActionComplete',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      currentBpm,
                      key,
                      title: slapTitle,
                      skinId: skin.id,
                      currentKey,
                      track: {
                        id: _t.id,
                        title: _t.title,
                        isSoloed: _t.isSoloed,
                        isMuted: _t.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                  return undefined
                }}
              />
            </div>
          ))}

          {isLoading && (
            <Progress
              className="mt-4 h-12 w-full bg-transparent backdrop-blur-xl"
              value={progress}
              defaultValue={0}
              progressColor={skin.bgColor}
              text={isSplitting ? 'Splitting...' : 'Generating...'}
              textColor={skin.textColor}
            />
          )}
        </div>

        <div className="fixed bottom-0 z-50 flex w-screen flex-col items-center justify-center gap-6 bg-gradient-to-b from-black/0 to-black/80 pb-6 pt-2 text-center">
          <Transport
            playAll={() => {
              playAll()
              mixpanel.track({
                eventName: 'PlayAll',
                data: {
                  currentPrompt,
                  duration: lockedDuration,
                  bpm,
                  currentBpm,
                  key,
                  title: slapTitle,
                  skinId: skin.id,
                  currentKey,
                  trackLength: tracks.length,
                  parentId: slapId.current
                }
              })
            }}
            pauseAll={() => {
              pauseAll()
              mixpanel.track({
                eventName: 'PauseAll',
                data: {
                  currentPrompt,
                  duration: lockedDuration,
                  bpm,
                  currentBpm,
                  key,
                  title: slapTitle,
                  skinId: skin.id,
                  currentKey,
                  trackLength: tracks.length,
                  parentId: slapId.current
                }
              })
            }}
            startRecording={startRecording}
            stopRecording={stopRecording}
            isRecording={isRecording}
            PlusButton={PlusButton}
          />
          <ActionMenu
            track={selectedTrack}
            deleteTrack={deleteTrack}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleShare={update}
            unselectTrack={() => {
              setSelectedTrack(null)
            }}
            // reset={reset}
          />
        </div>
      </main>
    </AnimationMachineProvider>
  )
}

const AddButton = () => {
  const { skin } = useSkin()
  const { addButtonRef } = useAnimationMachine()

  return (
    <div
      ref={addButtonRef}
      title="Add Tracks"
      className={cn('flex h-[48px] w-[48px] items-center justify-center rounded-full bg-primary')}
      style={{
        backgroundColor: skin.bgColor,
        color: skin.textColor
      }}
    >
      <LuPlus className="rounded-full" size={24} />
    </div>
  )
}

export default Looper
