import WavEncoder from 'wav-encoder'

export interface Recorder {
  start: () => void
  stop: () => Promise<Blob>
  pause: () => void
  resume: () => void
}

const getSupportedMimeType = (): string => {
  const mimeTypes = ['audio/webm;codecs=opus', 'audio/webm', 'audio/ogg;codecs=opus', 'audio/ogg', 'audio/mp4']
  for (const type of mimeTypes) {
    if (MediaRecorder.isTypeSupported(type)) return type
  }
  throw new Error('No supported MIME type found for audio recording.')
}

const convertToWav = async (audioChunks: Blob[]): Promise<Blob> => {
  const arrayBuffer = await new Response(new Blob(audioChunks)).arrayBuffer()
  const audioContext = new AudioContext()
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
  const wavData = await WavEncoder.encode({
    sampleRate: audioBuffer.sampleRate,
    channelData: [...Array(audioBuffer.numberOfChannels)].map((_, i) => audioBuffer.getChannelData(i))
  })
  return new Blob([wavData], { type: 'audio/wav' })
}

export const createRecorder = (format: 'native' | 'wav' = 'native'): Recorder => {
  const mimeType = getSupportedMimeType()
  const audioCtx = Howler.ctx
  const mediaStreamDestination = audioCtx.createMediaStreamDestination()

  // Route Howler's master gain node output to the destination
  Howler.masterGain.connect(mediaStreamDestination)

  const mediaRecorder = new MediaRecorder(mediaStreamDestination.stream)
  let audioChunks: Blob[] = []

  return {
    start: () => {
      if (mediaRecorder.state !== 'inactive') return
      mediaRecorder.start()
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data)
        }
      }
    },
    pause: () => {
      if (mediaRecorder.state === 'recording') {
        mediaRecorder.pause()
      }
    },
    resume: () => {
      if (mediaRecorder.state === 'paused') {
        mediaRecorder.resume()
      }
    },
    stop: () =>
      new Promise<Blob>((resolve) => {
        if (!mediaRecorder || (mediaRecorder.state !== 'recording' && mediaRecorder.state !== 'paused')) {
          return resolve(new Blob([], { type: mimeType }))
        }
        mediaRecorder.addEventListener('stop', async () => {
          const audioBlob =
            format === 'wav' ? await convertToWav(audioChunks) : new Blob(audioChunks, { type: mimeType })
          audioChunks = []
          // TODO: should probably disconnect the recorder destination to free up resources
          resolve(audioBlob)
        })
        mediaRecorder.stop()
      })
  }
}
